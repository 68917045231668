import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './green-flag.css';
import greenFlagIcon from './icon--green-flag.svg';

const GreenFlagComponent = function (props) {
    const { active, className, onClick, title, ...componentProps } = props;

    const isEmbedded =
        new URLSearchParams(window.location.search).get('embedded') === 'true';
    return (
        <img
            className={classNames(className, styles.greenFlag, {
                [styles.isActive]: active,
                [styles.hidden]: isEmbedded,
            })}
            id="run-button"
            draggable={false}
            src={greenFlagIcon}
            title={title}
            onClick={onClick}
            {...componentProps}
        />
    );
};
GreenFlagComponent.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
};
GreenFlagComponent.defaultProps = {
    active: false,
    title: 'Go',
};
export default GreenFlagComponent;
